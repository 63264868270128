import { FinancialSource, InvestmentType, RehabType } from '../api/_base/generated/data-contracts';
import { SelectListItemProps } from '../components/design-system';

export const statesArray = [
  { title: 'AK', value: 'AK' },
  { title: 'AL', value: 'AL' },
  { title: 'AR', value: 'AR' },
  { title: 'AZ', value: 'AZ' },
  { title: 'CA', value: 'CA' },
  { title: 'CO', value: 'CO' },
  { title: 'CT', value: 'CT' },
  { title: 'DC', value: 'DC' },
  { title: 'DE', value: 'DE' },
  { title: 'FL', value: 'FL' },
  { title: 'GA', value: 'GA' },
  { title: 'HI', value: 'HI' },
  { title: 'IA', value: 'IA' },
  { title: 'ID', value: 'ID' },
  { title: 'IL', value: 'IL' },
  { title: 'IN', value: 'IN' },
  { title: 'KS', value: 'KS' },
  { title: 'KY', value: 'KY' },
  { title: 'LA', value: 'LA' },
  { title: 'MA', value: 'MA' },
  { title: 'MD', value: 'MD' },
  { title: 'ME', value: 'ME' },
  { title: 'MI', value: 'MI' },
  { title: 'MN', value: 'MN' },
  { title: 'MO', value: 'MO' },
  { title: 'MS', value: 'MS' },
  { title: 'MT', value: 'MT' },
  { title: 'NC', value: 'NC' },
  { title: 'ND', value: 'ND' },
  { title: 'NE', value: 'NE' },
  { title: 'NH', value: 'NH' },
  { title: 'NJ', value: 'NJ' },
  { title: 'NM', value: 'NM' },
  { title: 'NV', value: 'NV' },
  { title: 'NY', value: 'NY' },
  { title: 'OH', value: 'OH' },
  { title: 'OK', value: 'OK' },
  { title: 'OR', value: 'OR' },
  { title: 'PA', value: 'PA' },
  { title: 'PR', value: 'PR' },
  { title: 'RI', value: 'RI' },
  { title: 'SC', value: 'SC' },
  { title: 'SD', value: 'SD' },
  { title: 'TN', value: 'TN' },
  { title: 'TX', value: 'TX' },
  { title: 'UT', value: 'UT' },
  { title: 'VA', value: 'VA' },
  { title: 'VI', value: 'VI' },
  { title: 'VT', value: 'VT' },
  { title: 'WA', value: 'WA' },
  { title: 'WI', value: 'WI' },
  { title: 'WV', value: 'WV' },
  { title: 'WY', value: 'WY' }
];

export const individualEntityType = 'SELF';

export type VestingEntityType = 'CORP' | 'LLC' | 'LLP' | 'SCORP' | 'SELF' | '';
type EntityTypeObject = SelectListItemProps & { value: VestingEntityType };

export const entityTypes: EntityTypeObject[] = [
  { label: 'Individual/Self', value: individualEntityType },
  { label: 'Corporation', value: 'CORP' },
  { label: 'LLC', value: 'LLC' },
  { label: 'LLP', value: 'LLP' },
  { label: 'S Corporation', value: 'SCORP' }
];

export const fundingSourcesOptions: Array<{
  label: string;
  value: `${FinancialSource}`;
}> = [
  { label: 'Hard Money (licensed lender)', value: 'Hard Money' },
  { label: 'Private Money (non-licensed lender)', value: 'Private Money (Non-Licensed Lender)' },
  { label: 'Cash', value: 'Cash' },
  { label: '1031 Exchange', value: '1031 Exchange' },
  { label: 'Line of Credit', value: 'Line of Credit' }
];

export const investmentStrategyOptions: Array<{
  label: string;
  value: `${InvestmentType}`;
}> = [
  { label: 'Fix and Flip', value: 'Fix & Flip' },
  { label: 'Rehab & Rent (long term)', value: 'Rehab & Rent (Long Term)' },
  { label: 'Rehab & Rent (short term)', value: 'Rehab & Rent (Short Term)' },
  { label: 'Owner Financed', value: 'Owner Financed' },
  { label: 'Builder (new construction)', value: 'Builder (New Construction)' }
];

export const rehabLevelOptions: Array<{
  label: string;
  value: `${RehabType}`;
}> = [
  { label: 'Light Updating', value: 'Light Updating' },
  { label: 'Full Cosmetic', value: 'Full Cosmetic' },
  { label: 'Large Fixer', value: 'Large Fixer' },
  { label: 'Tear Down', value: 'Tear Down' }
];

export const connectedInvestorsReferralSource = 'Connected Investors';

export const marketReferralSources: string[] = [
  'Friend or family',
  'Facebook',
  'Online search',
  'Email',
  'Website',
  'Dr. Phil',
  'News article',
  'Events',
  'Bigger Pockets',
  'Real estate agent',
  'Onboarding Advisor call',
  'Postal Mail',
  'Other'
];

export const PROPERTY_EXTENDED_LISTING = 'EXTENDED';
export const PROPERTY_EXPRESS_LISTING = 'EXPRESS';
export const PROPERTY_STANDARD_AUCTION = 'AUCTION';
export const PROPERTY_HIGHEST_AND_BEST = 'HIGHEST & BEST';
export const OFFER_WON_STATUS = 'WON';
export const OFFER_ACTIVE_STATUS = 'ACTIVE';
export const OFFER_LOST_STATUS = 'LOST';
export const OFFER_PENDING_STATUS = 'PENDING';
export const STANDARD_AUCTION = 'Standard Auction';
export const EmptyColumnValue = '---';

// TODO: This needs to be done a better way. Might want to move the tooltip over to the svg code
export const markerData = [
  { id: 'austin', name: 'Austin, TX', left: '47.2%', top: '77%' },
  // { id: 'atlanta', name: 'Atlanta, GA', left: '71.5%', top: '62.5%' },
  // { id: 'nashville', name: 'Nashville', left: '67%', top: '56%' },
  // { id: 'myrtle', name: 'Myrtle Beach, SC', left: '80.7%', top: '60.5%' },
  // { id: 'greenville', name: 'Greenville, SC', left: '75%', top: '58.5%' },
  // { id: 'charleston', name: 'Charleston, SC', left: '79.5%', top: '64%' },
  { id: 'dallas', name: 'Dallas, TX', left: '49.5%', top: '70%' },
  // { id: 'denver', name: 'Denver, CO', left: '36%', top: '43.5%' },
  // { id: 'lauderdale', name: 'Fort Lauderdale, FL', left: '81.5%', top: '87.5%' },
  // { id: 'myers', name: 'Fort Myers, FL', left: '78.5%', top: '86%' },
  // { id: 'worth', name: 'Fort Worth, TX', left: '47.5%', top: '67.5%' },
  // { id: 'fresno', name: 'Fresno, CA', left: '9.5%', top: '47%' },
  { id: 'houston', name: 'Houston, TX', left: '51.4%', top: '80%' },
  { id: 'inlandEmpire', name: 'Inland Empire, CA', left: '15%', top: '57%' },
  // { id: 'jacksonville', name: 'Jacksonville, FL', left: '77.5%', top: '75%' },
  // { id: 'lasVegas', name: 'Las Vegas, NV', left: '16.5%', top: '51%' },
  { id: 'losAngeles', name: 'Los Angeles, CA', left: '11%', top: '57%' },
  { id: 'oakland', name: 'Oakland, CA', left: '6.5%', top: '39%' },
  { id: 'orangeCounty', name: 'Orange County, CA', left: '12.7%', top: '59%' },
  // { id: 'orlando', name: 'Orlando, FL', left: '78.2%', top: '80.3%' },
  // { id: 'phoenix', name: 'Phoenix, AZ', left: '21.5%', top: '64.5%' },
  { id: 'sacramento', name: 'Sacramento, CA', left: '8%', top: '36.5%' },
  // { id: 'saltLakeCity', name: 'Salt Lake City, UT', left: '25%', top: '38.5%' },
  { id: 'sanAntonio', name: 'San Antonio, TX', left: '45.5%', top: '82%' },
  { id: 'sanDiego', name: 'San Diego, CA', left: '13.1%', top: '61.5%' }
  // { id: 'seattle', name: 'Seattle, WA', left: '12.5%', top: '6.5%' }
  // { id: 'tampaBay', name: 'Tampa Bay, FL', left: '77.6%', top: '83.5%' }
];

export type Testimonial = {
  message: string;
  name: string;
};

export const testimonials: Testimonial[] = [
  {
    message:
      '“I’ve found properties I can’t find  elsewhere. The Sundae properties are gems because they are direct to the seller.”',
    name: 'ANDREA Y.'
  },
  {
    message:
      '“Sundae saves me 2-3 hours per day following up with listing agents on the MLS and 2-3 hours in traffic.”',
    name: 'LAWRENCE B.'
  },
  {
    message:
      '“I love the ability to analyze each property before making an offer on it. It’s super convenient!”',
    name: 'HUGO M.'
  },
  {
    message:
      '“I’m able to refocus my time and energy away from finding properties and into other aspects of my business.”',
    name: 'JORDAN C.'
  },
  {
    message:
      '“I no longer have to schedule inspections, make outbound dials, or go out on appointments. Sundae saves me time and money!”',
    name: 'ERIN S.'
  }
];
